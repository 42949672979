<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('common.app-apps-overview')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleCreate"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          v-if="isPush && scope.row.status === appStatusValue.normal"
          class="opt-btn"
          icon="el-icon-upload2"
          type="warning"
          size="mini"
          plain
          @click="handlePublishClick(scope.row)"
          >{{ $t('general.push') }}</el-button
        >
        <el-button
          v-if="isSwitch && isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          @click="handleDisableClick(scope.row)"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="isSwitch && !isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row)"
          >{{ $t('general.enable') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import i18n from '@/i18n'

import { searchFormConfig } from './config/application.form'
import { contentTableConfig } from './config/application.table'

import { appStatusValue } from '../../../utils/constant/const'
import { usePermission } from '@/hooks/use-permission'

// 表格基础配置项
const tableOptions = {
  moduleName: 'file', // 所属的模块
  pageName: 'applications', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  pageAction: 'file/handleApplicationList', // 获取表格的Action
  deleteRecordAction: 'file/handleDeleteApplication', // 删除record的Action
  pageCountGetter: 'file/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'file/pageListData' // 获取表格列表数据getters
}

const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')
const pageSearchRef = ref('')
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
const { t } = i18n.global

// 获取页面操作的权限
const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)

const isPush = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'push'
)

const isNormalStatus = (status) => {
  return status === appStatusValue.normal
}

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/file/app/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/file/app/edit/${id}`)
}

const handleCreate = () => {
  router.push('/file/app/new')
}

const handlePublishClick = async (row) => {
  ElMessageBox.confirm(
    'Are you sure to push this application?',
    t('popup.confirm-warning-title'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      await handleMessageCommit('file/handlePublishApplicationAction', row.id)
      pageInfoChangeAction()
    })
    .catch((action) => {
      if (action === 'cancel') {
        ElMessage({
          type: 'info',
          message: t('popup.cancel-tip')
        })
      }
    })
}

const handleDisableClick = async (row) => {
  handleMessageCommit('file/handleApplicationDisableAction', row.id).then(
    () => {
      pageContentRef.value.getPageData()
    }
  )
}

const handleEnableClick = async (row) => {
  handleMessageCommit('file/handleApplicationEnableAction', row.id).then(() => {
    pageContentRef.value.getPageData()
  })
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
